.item_icon {
  font-size: 28px;

  svg {
    fill: var(--neutral-50);
  }

}

.info_text {
  width: 434px;
  height: 172px;
  color: var(--neutral-50);

  ul {
    list-style-type: decimal;
    margin-left: 21px;
    margin-top: 10px;
    padding-left: 0;
    li {
      padding-bottom:5px;
    }
  }
}

