.ram_icon {
  font-size: 20px;

  svg {
    fill: var(--neutral-50);
  }
}

.chart_icon {
  font-size: 20px;

  svg {
    fill: var(--primary-60);
  }
}


.radio_btn {
  background: #E5E7EB;
}

.radio_btn_checked {
  background: #FFFFFF;
}

.setting_popup_btn {
  cursor: pointer;
  margin-right: 10px;
}


.control_row {
  margin-top: 5px;
  display: flex;
}
