.page {

  &.userPage {
    display: flex;
    align-items: flex-start;
    padding-bottom: 100px;

    .mainContent {
      flex-grow: 1;
      min-width: 300px;
    }

    .rightBar {
      flex: 0 0 253px;
      padding: 0;
      margin-left: 30px;
    }
  }
}

.label {
  position: relative;
  height: 99px;

}

.title {
  position: absolute;
  bottom: 10px;
  left: 30px;
}

.header {
  width: 90%;
  height: 35px;
  border-top: 1px solid #BAC1CC;
  margin-left: 30px;
  background-color: #fbfbfc;
  border-bottom: 1px solid #BAC1CC;
}

.form {
  margin-top: 30px;
  display: flex;

  &_left {
    width: 50%;
    position: relative;
  }

  &_right {
    width: 50%;
    position: relative;
    margin-left: 25px;
  }
}

.nav_btn {
  margin-top:21px;
}

.nav {
  display: flex;
  text-decoration: none;
  padding-top: 7px;
  padding-right: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  float: left;

  a {
    text-decoration: none;
  }
}

.nav:hover {
  background-color: #EBEEF2;
}

.error {
  color: var(--error-60);
}

.float_right {
  float: right;
}

.required {
  color: red;
}

.notification {
  width: 384px;
  position: absolute;
  bottom: 15px;
  right: 15px;
}
