.wrapper {
    display: inline-flex;
    margin-bottom: 10px;
    .input {
        display: none;
    }
    .label {
        height: 12px;
        font-size: 15px;
        line-height: 24px;
        padding-left: 34px;
        position: relative;
        &:hover {
            cursor: pointer;
        }
        &::before {
            content: '';
            position: absolute;
            width: 24px;
            height: 24px;
            left: 0;
            top: 0;
            border-radius: 2px;
            border: 1px solid rgba(0, 0, 0, 0.2);
        }
    }
    &.readonly label {
        cursor: default;
    }
    &.wrapper_active {
        .label {
            &::after {
                content: '';
                position: absolute;
                width: 24px;
                height: 24px;
                left: 0;
                top: 0;
                background-image: url(../../assets/icons/checked.svg);
                background-position: center;
                background-repeat: no-repeat;
            }
        }
    }
}