.form {
  position: absolute;
  top: 180px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}

.part {
  display: flex;
  height: 388px;
  width: 412px;
  gap: 15px;

  &_left {
    background: #232431;
    height: 388px;
    width: 412px;
    padding: 11px 20px 11px 20px;
    gap: 4px;

    position: relative;

  }

  &_right {

    height: 388px;
    width: 412px;
    border-radius: unset;

    padding: 20px 0px 0px 0px;
    gap: 32px;

    position: relative;
  }
}

.inner {
  width: 372px;
  height: 284px;
  gap: 20px;
}


.logo {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 10px;
  width: 162px;
  height: 50px;
  flex: none;
  flex-grow: 0;
  order: 0;

  font-family: Mulish-ExtraBold;
  font-size: 28px;
  font-weight: 900;
  line-height: 32px;
  letter-spacing: 0.04em;
  text-align: left;
  color: #FFFFFF;
}

.slogan {
  width: 300px;
  height: 56px;
  gap: 12px;
  position: absolute;
  bottom: 20px;
}

.text {
  font-family: Mulish-Regular;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.04em;
  text-align: left;
  display: flex;
  align-items: flex-end;
  color: #FFFFFF;
}

.rectangle {
  width: 40px;
  height: 4px;
  background: rgb(247, 86, 35);
  display: block;
  margin-bottom: 12px;
}

.label {
  margin-bottom: 4px;
}

.mulish_regular {
  font-family: Mulish-Regular;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.alert {
  height: 48px;
}

div.ant-alert-message {

  font-family: Mulish-Regular;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.item_icon {
  font-size: 20px;

  svg {
    font-size: 20px;
    fill: var(--neutral-50);
  }
}
