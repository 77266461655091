.item_icon {
  font-size: 15px;

  svg {
    fill: var(--neutral-50);

    &:hover {
      fill: var(--error-60);
    }
  }
}
