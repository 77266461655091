.filter_div {
  width: 420px;
  min-height: 115px;
  padding: 12px 16px 12px 16px;
}

.select_div {

}

.buttons_div {
  margin-top: 20px;
}
