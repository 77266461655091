.no_data {
  min-height: 250px;
  align-content: center;
  text-align: center;
}

.item_icon {
  font-size: 35px;

  svg {
    fill: var(--neutral-50);
  }
}


.no_data_div {
  margin-top: 10px;
  text-align: center;
}


