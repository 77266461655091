.page {

  &.userPage {
    display: flex;
    align-items: flex-start;
    padding-bottom: 100px;

    .mainContent {
      flex-grow: 1;
      min-width: 300px;
    }

    .rightBar {
      flex: 0 0 253px;
      padding: 0;
      margin-left: 30px;
    }
  }
}

.form {
  margin-top: 30px;
  display: flex;

  &_left {
    width: 50%;
    position: relative;
  }

  &_right {
    width: 50%;
    position: relative;
    margin-left: 25px;
  }
}

.card {
  width: 100%;
  height: 100%;
}

.nav_btn {
  margin-top: 45px;
}
.float_right {
  float: right;
}

.settings {
  display: flex;
  flex-direction: column;

}

.settings_row {
  margin-top:5px;
  display: flex;
}

.settings_row_text {
  margin-top:20px;
  display: flex;
}

.settings_f_cell {
  width:20%;
}

.settings_cell {
  width: 207px;
}

.settings_s_cell {
  width: 20%;
  margin-left:20px;
}

.settings_s_cell_event {
  width: 25%;
  margin-left:20px;
}

.settings_s_cell_severity {
  width: 15%;
  margin-left:20px;
}

.settings_val {
  padding-left: 20px;
}

.required {
  color: var(--error-60);
}

.popup_class {
  color: #F75623;
  a {
    color: #F75623;
  }
}

.notification {
  width: 384px;
  position: fixed;
  bottom: 35px;
  right: 55px;
}
