.aside {
  min-height: calc(100vh - 120px);

  ul {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-left: 0;
    margin-bottom: 50px;
  }

  .settings_link {
    margin-top: 30px;
  }

  .link {
    color: var(--gray-100);
    display: flex;
    align-items: center;
    gap: 10px;
    text-decoration: none;
    padding: 6px;
    background-color: var(--white);

    font-family: Mulish-Regular;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;

    svg {
      fill: var(--neutral-50);
    }

    .icon {
      width: 24px;
      text-align: center;
    }

    .count {
      margin: 3px 0;
      margin-left: 4px;
      background-color: var(--gray-10);
      padding: 5px;
      @media (max-width: 1000px) {
        display: none;
      }
    }

    .title {
      font-size: 15px;
      width: 141px;
      @media (max-width: 1000px) {
        display: none;
      }
    }

    &_active {
      background-color: #FEEEE9;
      color: #F75623;
      border-radius: 4px;

      svg {
        fill: var(--primary-60);
      }
    }

  }

  a.link:hover {


    color: #F75623;
  }

  .recent_header {
    margin-top: 40px;

    @media (max-width: 1000px) {
      display: none;
    }
  }

  .recent {
    a span.title {
      font-size: 12px;
      color: var(--blue-main);
    }

    @media (max-width: 1000px) {
      display: none;
    }
  }
}

.item_icon {
  font-size: 20px;

  svg {
    fill: var(--primary-60);
  }
}
