.button {
  color: white;
}

.navbar {
  width: 100%;
  padding-right: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ul {
    padding-left: 0;

    li {
      padding-bottom: 0px;
    }
  }

}

.links {
  list-style: none;
  display: flex;
}

.item {
  margin-left: 32px;

  a {
    display: inline-block;
    padding: 18px 0 0 0;
    text-decoration: none;
    color: white;
  }

  &_active {
    a {

    }
  }
}

.split {
  float: right;
}

.btn {
  color: var(--white);
}

.btn:hover {
  background-color: var(--header-black);
}

.cli {
  color: white;
  width: 100%;
  flex-direction: column;
  background-color: var(--header-black);
  position: absolute;
  z-index: 10000;
}

.logo {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 10px;
  width: 162px;
  height: 50px;
  flex: none;
  flex-grow: 0;
  order: 0;

  font-family: Mulish-ExtraBold;
  font-size: 28px;
  font-weight: 900;
  line-height: 32px;
  letter-spacing: 0.04em;
  text-align: left;
  color: #FFFFFF;
}


.div_item {
  height: 4px;
  width: 100%;

  &_active {
    background: var(--primary-60);
  }
}

.customizeToolbar {
  minHeight: 56px;
}

.right_group_button {
  width: 240px;
  position: absolute;
  right: 15px;
  display: flex;
  align-items: center;
}

.badge {
  color: #FFFFFF;
}

.item_icon_neutral {
  font-size: 20px;

  svg {
    fill: var(--neutral-00);
  }
}

.item_icon_primary_60 {
  font-size: 20px;
  padding-left: 6px;

  svg {
    fill: var(--primary-60);
  }
}

.circle {
  width: 32px;
  height: 32px;
  background: var(--neutral-70);
  -moz-border-radius: 16px;
  -webkit-border-radius: 16px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  margin: 0 20px 0 0;
}

.cli_btn {
  margin: 0 40px 0 auto;
}
