.modal-content {
    border: none;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
}

.modal-header {
    border-bottom: none;
}

.modal-title {
    flex-grow: 1;
    text-transform: uppercase;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    color: #3B4047;
}

.modal-body > div {
    margin-bottom: 18px;
}

.modal-footer {
    border-top: none;
    justify-content: center;
}

.modal-footer .btn {
    font-size: 14px;
    border: 2px solid #E8762D;
    line-height: 40px;
    padding: 0 20px;
    border-radius: 4px;
}

.modal-footer .btn.btn-primary {
    color: #fff;
    background: #E8762D;
}

.modal-footer .btn.btn-secondary {
    background: #fff;
    color: #E8762D;
}

.tooltip {
    background: #fff;
    color: #000;
    font-size: 12px;
    opacity: 1;
    padding: 12px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

@media (min-width: 1001px) {
    .tooltip.mob-only {
        display: none !important;
    }
}

body > div:not(#root) {
    display: none;
}


.header_1 {
    font-family: Mulish-Regular;
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
    text-align: left;
}

.header_2 {

    font-family: Mulish-Regular, serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;

}

.monitoring_value {
    font-family: Mulish-Regular, serif;
    font-size: 48px;
    font-weight: 700;
    line-height: 46px;
    text-align: left;
}

.body_1_long {
    font-family: Mulish-Regular, serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;

}

.body_1_short {
    font-family: Mulish-Regular, serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;

}

.button_1 {
    font-family: Mulish-ExtraBold, serif;
    font-size: 14px;
    font-weight: 800;
    line-height: 20px;
    text-align: left;
}

.button_2 {
    font-family: Mulish-ExtraBold, serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
}

.attention_20_bg {
    background-color: var(--attention-20);
}

.primary_80 {
    color: var(--primary-80);
}

.primary_60 {
    color: var(--primary-60);
}
.success_20_bg {
    background-color: var(--success-20);
}

.error_20_bg {
    background-color: var(--error-20);
}

.neutral_90 {
    color: var(--neutral-90);
}
.neutral_50 {
    color: var(--neutral-50);
}
.neutral_40 {
    color: var(--neutral-40);
}
.neutral_00 {
    color: var(--neutral-00);
}

.neutral_10_bg {
    background-color: var(--neutral-10);
}

.scrollable_div {
    height: 660px;
    width: 100%;
    overflow: auto;
}

@media (min-height: 700px) and (max-height: 900px) {
    .scrollable_div {
        height: 420px;
        width: 100%;
        overflow: auto;
    }
}

@media (min-height: 1024px) and (max-height: 1079px) {
    .scrollable_div {
        height: 660px;
        width: 100%;
        overflow: auto;
    }
}

.status_planned {
    background-color: var(--primary-20);
    border:none;
}

.status_processing {
    background-color: var(--success-20);
    border:none;
}

.status_finished {
    background-color: var(--neutral-10);
    border:none;
}

.status_error {
    background-color: var(--error-10);
    border:none;
}
