.no_filter_data {
  min-height: 250px;
  align-content: center;
  text-align: center;

}

.item_icon {
  font-size: 35px;

  svg {
    fill: var(--neutral-50);
  }
}
