.item_icon {
  font-size: 15px;
  padding-left: 10px;

  svg {
    fill: var(--neutral-50);
    &:hover {
      fill: var(--error-60);
    }
  }
}

.action_cell {
  display: flex;
}

.percent {
  width: 50px;
}

.action {

}


