.form {
    background-color: var(--white);
    padding: 32px 46px 40px;
    border-radius: 6px;
}
.title {
    text-align: left;
    margin-bottom: 22px;
    font-family: Mulish-Bold;
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
}

.btn {
    margin-top: 25px;
    &_wrapper {
        margin-top: 25px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }
}

.input_wrapper {
    display: flex;
    flex-direction: column;
}

.frame {
    width: 372px;
    height:344px;
    gap: 20px;
    margin-left: 20px;
}

.btn_cont {
    width: 372px;
    height: 32px;
    position: absolute;
    bottom: 20px;
    left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: Mulish-Regular;
    font-size: 14px;
    font-weight: 800;
    line-height: 20px;

}

.flex_button{
    width: 100%;
}

.btn_text {
    font-family: Mulish-Regular;
    font-size: 14px;
    font-weight: 800;
    line-height: 20px;
}
