.cli {
}

.terminal {
  margin-left: 10px;
  overflow-y: scroll;
}

.terminal_input {
}

input {
  border: none;
  outline: none;
  padding: 0;
  background-color: transparent;
}

.expand_btn {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.btn_v {
  background: #282828;
  color:#FFFFFF;
  border:none;
}

.btn_v:focus {
  color:#282828;
  background: #FFFFFF;
  outline: none;
  border: none;
  cursor:none;

}

