.page {

  &.userPage {
    display: flex;
    align-items: flex-start;
    padding-bottom: 100px;

    .mainContent {
      flex-grow: 1;
      min-width: 300px;
    }

    .rightBar {
      flex: 0 0 253px;
      padding: 0;
      margin-left: 30px;
    }
  }
}

.label {
  position: relative;
  height: 99px;

}

.title {
  position: absolute;
  bottom: 10px;
  left: 30px;
}

.item_icon {
  width: 90%;
  height: 35px;
  border-top: 1px solid var(--gray-300);
  margin-left: 30px;
  background-color: #fbfbfc;
  border-bottom: 1px solid var(--gray-300);
}


.form {
  margin-top: 30px;
  display: flex;

  &_left {
    width: 50%;
    position: relative;
  }

  &_right {
    width: 50%;
    position: relative;
    margin-left: 25px;
  }
}

.card {
  width: 100%;
  height: 100%;
}

.card_no_data {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.float_right {
  float: right;
}

.required {
  color: red;
}

.nav_btn {
  margin-top:21px;
}

.uploaded_file {
  width: 100%;
  height: 32px;
  border: 1px solid #9BA0AA;
  border-radius: 4px;

  margin-top: 10px;
  padding-left: 16px;
  align-content: center;
}

.error {
  color: var(--error-60);
}
