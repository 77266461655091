.page {
    padding: 70px 50px 0px;

    &.domainPage {
        display: none;
        align-items: flex-start;
        position: relative;

        &.loaded {
            display: flex;
        }

        .mainContent {
            flex-grow: 1;
            min-width: 300px;
        }

        .rightBar {
            flex: 0 0 253px;
            padding: 0;
            margin-left: 30px;
        }
    }
}

.datarow {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .label {
        flex: 0 0 300px;
    }
}

.title, .title2 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 50px;
    max-width: 750px;
}

.title2 {
    margin-top: 90px;
    text-transform: none;
}

.buttons {
    margin-top: 30px;
    
    button {
        padding: 10px;
    }
}

.description {
    max-width: 750px;
}

.input {
    width: 322px;
    margin-bottom: 20px;
}

.long_input {
    margin-bottom: 20px;
}

.button {
    margin-bottom: 20px;
    gap: 10px;
    padding: 10px 20px;
}

.table {
    width: 100%;
    min-width: 700px;
    margin-bottom: 70px;
    th {
        width: 200px;
    }
    &_wrapper {
        overflow-x: auto;
    }
    &_scroll {
        width: max-content;
        overflow-x: auto;
    }
}

.btn_create {
    display: inline-block;
    background: url(../../assets/icons/plus-in-circle-dk.svg);
    width: 15px;
    height: 15px;
    background-size: contain;
    margin: 0 5px;
}

.btn_edit {
    display: inline-block;
    background: url(../../assets/icons/edit.svg);
    width: 15px;
    height: 15px;
    background-size: contain;
    margin: 0 5px;
}

.btn_del {
    display: inline-block;
    background: url(../../assets/icons/delete.svg);
    width: 12px;
    height: 15px;
    background-size: contain;
    margin: 0 5px;
}

.add_systems_list {
    max-height: 300px;
    overflow: auto;
    padding: 0 10px;

    .new_system_item {
        margin: 5px 0;
    }
}