.notices_list {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 99;
  width: 30%;
}

.notice {
  display: block;
  margin-top: 10px;
  position: relative;
  border-radius: 3px;

  &.error {
    background: #fcc;
    border: 1px solid #f99;

  }

}

.row {

}

.cell {

}
