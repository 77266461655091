.page {

  &.userPage {
    display: flex;
    align-items: flex-start;
    padding-bottom: 100px;

    .mainContent {
      flex-grow: 1;
      min-width: 300px;
    }

    .rightBar {
      flex: 0 0 253px;
      padding: 0;
      margin-left: 30px;
    }
  }
}

.label {
  position: relative;
  height: 99px;
}

.title {
  position:absolute;
  bottom:10px;
  left:30px;
}

.item_icon {
  width: 90%;
  height: 35px;
  border-top: 1px solid #BAC1CC;
  margin-left: 30px;
  background-color: #fbfbfc;
  border-bottom: 1px solid #BAC1CC;
}

.form {
  margin-top: 30px;
  display: flex;

  &_left {
    width: 50%;
    position: relative;
  }

  &_right {
    width: 50%;
    position: relative;
    margin-left: 25px;
  }
}

.error {
  color: var(--error-60);
}

.nav {
  display: flex;
  text-decoration: none;
  padding-top: 7px;
  padding-right: 10px;
  margin-top:20px;
  margin-bottom:20px;
  float: left;

  a {
    text-decoration: none;
  }
}

.height {
  height:100px;
}

.pageable {
  marginTop:10px;
  width:50%;
  float:right;
}

.nav_btn {
  margin-top:21px;
}

.float_right {
  float: right;
}


.user_item_icon {
  font-size: 28px;
  margin-bottom: 10px;

  svg {
    fill: var(--neutral-50);
  }

}


.required {
  color: var(--error-60);
}


.row_error {
  background-color: var(--error-60);
}

.notification {
  width: 384px;
  position: fixed;
  bottom: 35px;
  right: 55px;
}

.popup_select_div {
  margin-top: 5px;
  border: 1px solid #9BA0AA;
  border-radius: 4px;
}

.switch_div {
  margin: 20px 0 20px 0;

}

.check_item_icon {
  font-size: 18px;

  svg {
    fill: var(--success-50);
  }
}

.close_item_icon {
  font-size: 18px;

  svg {
    fill: var(--error-60);
  }
}
