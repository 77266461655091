.content {
    display: flex;
    padding:25px;
    background: var(--gray-logo);
    height: calc(100vh - 56px );
}

.aside {
    min-width: 312px;
    @media (max-width: 1000px) {
        width: 120px;
    }
}

.main {
    overflow: hidden;
    overflow-x: auto;
    width: 100%;
    position: relative;
    padding-left: 10px;
}

.label {
    height: 100px;
    border-right: 1px solid var(--gray-300);
    border-bottom: 1px solid var(--gray-300);
    padding: 6px 17px 6px 20px;
    display: flex;
    align-items: flex-end;
    color: var(--gray-300);
}


